import {
  fetchMasterDataServicePost,
  deleteMasterDataService,
  fetchMasterDataService,
} from 'library/api/master-data.service';
import { batchActions } from 'redux-batched-actions';
import { logNetworkError } from 'library/utilities/logError';
import moment from 'moment';
import strings from 'resources/locales/Translate';
import { URLS } from 'library/common/commonConstants/ApiUrlConstants';
import { regex } from 'library/common/commonConstants/RegexConstants';
import Validators from 'library/utilities/Validators';
import {
  UPDATE_CREATE_SEM_FORM,
  FETCH_CREATE_SEM_TRANSACTION_DETAILS,
  FETCH_LOANS_INTENDED,
  FETCH_BESTWAY_TO_REACH,
  FETCH_DATA_WITHIN,
  FETCH_DATA_WITH_CUSTOMER,
  FETCH_MARKET_SEGMENTS,
  FETCH_RELATIONSHIP_WITH_ZEISS,
  FETCH_STATUS_OF_SAMPLE,
  FETCH_PRIMARY_INSTRUMENT,
  FETCH_ADDITIONAL_INSTRUMENT,
  FETCH_SKILL_LEVEL,
  RESET_CREATE_SEM_FORM,
  FETCH_ALL_UNEXECUTED_LOANS,
  FETCH_ESTIMATE_DAYS,
  FETCH_ZMCC_SHIPPING_ADDRESS,
  FETCH_TRANSACTION_ADDRESS,
  FETCH_MARKET_SUB_SEGMENTS,
  FETCH_SYSTEM_CATEGORIES,
  CLICKED_ADD_IN_DEMO_CENTRE_TAB,
  CLICKED_ADD_IN_TODO_TAB,
  FETCH_SBU_TYPES
} from './SemBooking.constants';
import { changeLoader, toggleActionMessage } from 'library/common/commonActions/AppActionsActions';
import {
  generalInformationModel,
  schedulingInformationModel,
  projectDetailsModel
} from "./Components/Assignment/Tabs/GeneralInformation/GeneralInformation.constants";
import services from './SemBooking.service';
import { validateForm } from 'library/utilities/ValidateForm.util';
import { scrollToTop } from 'library/utilities/scrollActions';
import {
  customerDetilsModel,
  primaryPointOfContactModel,
  customerBackgroundModel
} from "./Components/Assignment/Tabs/CustomerInformation/CustomerInformation.constants";
import {
  mainApplicationChecklist
} from "./Components/Assignment/Tabs/ApplicationSampleDetails/ApplicationSampleDetails.constants";
import zeissInstance from 'library/utilities/AxiosInstance';
import { fetchFromStorage, removeFromStorage, saveToStorage } from 'library/utilities/storage';
import { identifiers } from 'library/common/commonConstants/IdentifiersConstants';
import Axios from 'axios';
import { saveAs } from 'file-saver';
import { xbMainApplicationChecklist } from './Components/Assignment/Tabs/XBApplicationSamplesDetails/XBApplicationSampleDetails.constants';
import { xrmSamplesSpecModel } from './Components/Assignment/Tabs/XRMApplicationSamplesDetails/XRMApplicationSamplesDetails.constants';
import store from '../../../main/store/configureStore'
import { containsIgnoreCase } from 'library/utilities/stringUtils';
import { string } from 'prop-types';

export const updateSEMForm = payload => (
  {
    type: UPDATE_CREATE_SEM_FORM,
    payload,
  }
);

const delay = ms => new Promise(res => setTimeout(res, ms));
export const handleHomePageFilters = (stocks, user) => {
  const filters = fetchFromStorage(identifiers.zmccHomePageFilters)
  if(filters !== null && filters !== undefined && filters.stocks && filters.stocks >0){
    let stocksFullValue = filters.stocksFullValue
    let dataToSet = {
      stockInformationId: filters.stocks,
      stockInformationIdError: null,
      stockInformationIdFullValue: {
        id: filters.stocks,
        countryId: stocksFullValue.countryId,
        locationName: stocksFullValue.locationName,
        locationShortName: stocksFullValue.locationShortName,
        businessUnitId: 10,
        businessUnit: stocksFullValue.businessUnit,
        latitude: null,
        longitude: null,
      }
    }
    if(!sessionStorage.getItem('reloadCountPage'))
      removeFromStorage(identifiers.zmccHomePageFilters)
    return dataToSet
  }
  return {}
} 

export const getUnexecutedSemTransaction = (user, marketSegments, transactionsId,marketSubSegment) => async (dispatch, getState) => {
  dispatch(changeLoader(true));
  try{
    const crmData = fetchFromStorage(identifiers.zmccCrmParams);
    let marketSegment;
    if (marketSegments && marketSegments.length> 0 && crmData !== null) {
        marketSegment = marketSegments.find(item => crmData.marketSegment && containsIgnoreCase(crmData.marketSegment, item.staticCode)) || {id: 1, name: "Life Sciences", staticCode: "Life Sciences"};
    }
    let { createSEMFormReducer } = getState();
    const setCrmFormData = crmData !== null && setCrmData(crmData, marketSegment, marketSubSegment, user, createSEMFormReducer);
    if (crmData !== null) {
      dispatch({
        type: FETCH_CREATE_SEM_TRANSACTION_DETAILS,
        payload: { ...setCrmFormData },
      });
    }
    else{
      dispatch(getListOfUnexecutedSemTransaction());
      const res = transactionsId && await fetchMasterDataService(URLS.getUnexecutedZmccTransactionById(transactionsId));
      if (res && res.data && Object.keys(res.data).length>0){
        dispatch({
          type: FETCH_TRANSACTION_ADDRESS,
          payload: { ...res.data.transactions },
        })
        const formData = createFormData(res.data);
        dispatch({
          type: FETCH_CREATE_SEM_TRANSACTION_DETAILS,
          payload: { ...formData, ...res.data },
        });
        
      }
      else {
        dispatch({
          type: FETCH_CREATE_SEM_TRANSACTION_DETAILS,
          payload: { 
            generalInformationForm: {
              formData:
                {
                  email: user && user.email, 
                  mobile: user.phone,
                  requesterId: user && user.accountId,
                  requesterIdFullValue: user && {id : user.accountId , ...user},
                  ...handleHomePageFilters()
                }
              },
              logisticsAndCoordinationForm: {
                formData: {
                  zmccAttendeesList: []
                }
              },
              demoCenterInformationForm:{
                formData: {
                  zmccHardwareSoftwareAccessoriesList: []
                }
              }
              
            },
        });
      }
    }

    dispatch(changeLoader(false));
  }
  catch (error) {
    dispatch(toggleActionMessage(true, 'error', 'somethingWentWrongMessage'));
    dispatch(changeLoader(false));
  }
}

export const getListOfUnexecutedSemTransaction = () => async (dispatch) => {
    dispatch(changeLoader(true));
    try{
      const res = await fetchMasterDataService(URLS.zmccGetUnexecutedSemTransaction);
      if (res && res.data && res.data.length>0){
        dispatch({
          type: FETCH_ALL_UNEXECUTED_LOANS,
          payload: res.data ,
        });
      }
      else {
        dispatch({
          type: FETCH_ALL_UNEXECUTED_LOANS,
          payload: {},
        });
      }
      dispatch(changeLoader(false));
    }
    catch (error) {
      dispatch(toggleActionMessage(true, 'error', 'somethingWentWrongMessage'));
      dispatch(changeLoader(false));
    }
  }

export const createFormData = (zmccTransactionDetails) => {
  let generalInformationForm = {
    formData: {
      email: zmccTransactionDetails.email,
      mobile: zmccTransactionDetails.mobile,
      stockInformationId: zmccTransactionDetails.stockInformation && zmccTransactionDetails.stockInformation.id,
      stockInformationIdFullValue: zmccTransactionDetails.stockInformation ? zmccTransactionDetails.stockInformation :{},
      requesterId: zmccTransactionDetails.requestor && zmccTransactionDetails.requestor.accountId,
      requesterIdFullValue: zmccTransactionDetails.requestor && {id : zmccTransactionDetails.requestor.accountId , ...zmccTransactionDetails.requestor},
      intendedForId: zmccTransactionDetails.intendedFor && zmccTransactionDetails.intendedFor.intendedForId,
      intendedForIdFullValue: zmccTransactionDetails.intendedFor && {id : zmccTransactionDetails.intendedFor.intendedForId, name: zmccTransactionDetails.intendedFor.intendedForTranslated[0]['name'], staticCode: zmccTransactionDetails.intendedFor.staticCode},
      bestWayToReachId: zmccTransactionDetails.bestWayToReach && zmccTransactionDetails.bestWayToReach.bestWayToReachId,
      bestWayToReachIdFullValue : zmccTransactionDetails.bestWayToReach && {id : zmccTransactionDetails.bestWayToReach.bestWayToReachId, name: zmccTransactionDetails.bestWayToReach.bestWayToReachTranslated[0]['name'], staticCode: zmccTransactionDetails.bestWayToReach.staticCode},
      precallWithZmcc: zmccTransactionDetails.precallWithZmcc ? 'yes' : zmccTransactionDetails.precallWithZmcc === false ? 'no' : null,
    },
    isFormValid: false,
  };
  let additionInstrumentsFullvalueData =[];
  if (zmccTransactionDetails.additionalInstruments && zmccTransactionDetails.additionalInstruments.length >0) {
    zmccTransactionDetails.additionalInstruments.map(item =>
      {
        if(item.kitInformation == null){
          additionInstrumentsFullvalueData.push({kitInformationId: 0 , kitName:"other"})
        }
        else{
          additionInstrumentsFullvalueData.push({kitInformationId: item.kitInformation.kitInformationId, kitName:item.kitInformation.kitName})
        }
      }
    )
    }

  let additionInstrumentKitIds =[];
  if (zmccTransactionDetails.additionalInstruments && zmccTransactionDetails.additionalInstruments.length >0) {
    zmccTransactionDetails.additionalInstruments.map(item =>
      {
        if(item.kitInformation == null){
          additionInstrumentKitIds.push(0)
        }
        else{
          additionInstrumentKitIds.push(item.kitInformation.kitInformationId)
        }
      }
    )
  }

  let schedulingInformationForm = {
    formData: {
      suggestedDate: zmccTransactionDetails.suggestedDate && new Date(zmccTransactionDetails.suggestedDate),
      alternateDate: zmccTransactionDetails.alternateDate && new Date(zmccTransactionDetails.alternateDate),
      dueDate: zmccTransactionDetails.dueDate && new Date(zmccTransactionDetails.dueDate),
      estimatedDays: zmccTransactionDetails.estimateDurationDays && zmccTransactionDetails.estimateDurationDays.estimateDurationId,
      estimatedDaysFullValue: zmccTransactionDetails.estimateDurationDays && {
        id:zmccTransactionDetails.estimateDurationDays.estimateDurationId,
        name: zmccTransactionDetails.estimateDurationDays.estimateDurationDaysTranslated[0].days,
        staticCode: zmccTransactionDetails.estimateDurationDays.staticValues 
      },
      additionalInstrument: additionInstrumentKitIds,
      additionalInstrumentFullValue: additionInstrumentsFullvalueData,
      corrMicDetails: zmccTransactionDetails.corrMicDetails,
      kitInformationId: zmccTransactionDetails.kitInformation && zmccTransactionDetails.kitInformation.kitInformationId,
      kitInformationIdFullValue: zmccTransactionDetails.kitInformation ? zmccTransactionDetails.kitInformation : {},
      needDataWithinId: zmccTransactionDetails.needDataWithin && zmccTransactionDetails.needDataWithin.needDataWithinId,
      needDataWithinIdFullValue: zmccTransactionDetails.needDataWithin &&  {id : zmccTransactionDetails.needDataWithin.needDataWithinId, name: zmccTransactionDetails.needDataWithin.needDataWithinTranslated[0]['name'], staticCode: zmccTransactionDetails.needDataWithin.staticCode},
      zmccSystemCategoriesId: zmccTransactionDetails.zmccSystemCategories && zmccTransactionDetails.zmccSystemCategories.zmccSystemCategoryId,
      zmccSystemCategoriesIdFullValue: zmccTransactionDetails.zmccSystemCategories && {
        id:zmccTransactionDetails.zmccSystemCategories.zmccSystemCategoryId,
        name: zmccTransactionDetails.zmccSystemCategories.name,
        staticCode: zmccTransactionDetails.zmccSystemCategories.staticValues 
      },
    },
    isFormValid: false,
  };

  let projectDetailsForm = {
    formData: {
      opportunityId : zmccTransactionDetails.opportunityId,
      isItFirstDemo: zmccTransactionDetails.isItFirstDemo ? 'yes' : zmccTransactionDetails.isItFirstDemo === false ? 'no' : null,
      isCustomerApplyingForFunding: zmccTransactionDetails.isCustomerApplyingForFunding ? 'yes' : zmccTransactionDetails.isCustomerApplyingForFunding === false ? 'no' : null,
      comments: zmccTransactionDetails.comments,
      crmTaskId: zmccTransactionDetails.crmTaskId ? zmccTransactionDetails.crmTaskId : ''
    },
    isFormValid: false,
  };

  let customerDetilsForm ={
    formData: {
      customerName: zmccTransactionDetails.customerName,
      customerOrganization: zmccTransactionDetails.customerOrganization,
      customerDepartment: zmccTransactionDetails.customerDepartment,
      customerAddress: zmccTransactionDetails.customerAddress,
      customerEmail : zmccTransactionDetails.customerEmail,
      customerMobile: zmccTransactionDetails.customerMobile,
      customerWebsite : zmccTransactionDetails.customerWebsite,
      customerPointOfContact : zmccTransactionDetails.customerPointOfContact === true ? 'yes' : zmccTransactionDetails.customerPointOfContact === false ? 'no' : null,
      customerNDA : zmccTransactionDetails.customerNDA ? true : zmccTransactionDetails.customerNDA === false ? false : null,
      customerFunction: zmccTransactionDetails.customerFunction || "",
      customerDescisionMaker: typeof zmccTransactionDetails.isCustomerDecisionMaker === 'boolean' ? zmccTransactionDetails.isCustomerDecisionMaker: null,
      customerSkillLevelId: zmccTransactionDetails?.customerSkillLevelId || null,
      customerSkillLevelIdFullValue: zmccTransactionDetails?.customerSkillLevel && {id:zmccTransactionDetails.customerSkillLevel.skillLevelId , name:zmccTransactionDetails.customerSkillLevel?.skillLevelTranslated[0]?.name, staticCode:zmccTransactionDetails?.customerSkillLevel?.staticCode}
    },
    isFormValid: false,
  }

  let primaryPointOfContactForm ={
    formData: {
      pocName: zmccTransactionDetails.pocName,
      pocEmail : zmccTransactionDetails.pocEmail,
      pocDesignation : zmccTransactionDetails.pocDesignation,
    },
    isFormValid: false,
  }

  let customerBackgroundForm = {
    formData: {
      customerMarketSubsgement: zmccTransactionDetails.customerMarketSubsgement,
      customerMicroscopeExperience : zmccTransactionDetails.customerMicroscopeExperience,
      zeissCustomer : zmccTransactionDetails.zeissCustomer ? 'yes' : zmccTransactionDetails.zeissCustomer === false ? 'no' : null,
      marketSegmentId: zmccTransactionDetails.marketSegment && zmccTransactionDetails.marketSegment.marketSegmentId,
      marketSegmentIdFullValue: zmccTransactionDetails.marketSegment && {id: zmccTransactionDetails.marketSegment.marketSegmentId, name:zmccTransactionDetails.marketSegment.name, staticCode:zmccTransactionDetails.marketSegment.staticCode},
      relationshipId: zmccTransactionDetails.relationshipWithZeiss && zmccTransactionDetails.relationshipWithZeiss.relationshipId,
      relationshipIdFullValue: zmccTransactionDetails.relationshipWithZeiss && {id: zmccTransactionDetails.relationshipWithZeiss.relationshipId, name: zmccTransactionDetails.relationshipWithZeiss.relationshipWithZeissTranslated[0]['name'], staticCode:zmccTransactionDetails.relationshipWithZeiss.staticCode},
      customerMarketSubsgementId: zmccTransactionDetails.customerMarketSubsgement && zmccTransactionDetails.customerMarketSubsgement.customerMarketSubsgementId,
      customerMarketSubsgementIdFullValue: zmccTransactionDetails.customerMarketSubsgement && {id: zmccTransactionDetails.customerMarketSubsgement.customerMarketSubsgementId, name:zmccTransactionDetails.customerMarketSubsgement.customerMarketSubsgementTranslated[0].name, staticCode:zmccTransactionDetails.customerMarketSubsgement.staticCode},
      sbuType: zmccTransactionDetails?.sbuFullDetails && zmccTransactionDetails.sbuFullDetails.staticCode,
      sbuTypeFullValue: zmccTransactionDetails?.sbuFullDetails && {id:zmccTransactionDetails.sbuFullDetails.staticCode , name:zmccTransactionDetails.sbuFullDetails.name, staticCode:zmccTransactionDetails.sbuFullDetails.staticCode}
    },
    isFormValid: false,
  }
  let mainApplicationChecklistForm = {
    formData: {
      highResolutionImaging: zmccTransactionDetails.highResolutionImaging,
      eds : zmccTransactionDetails.eds,
      lowKVImaging : zmccTransactionDetails.lowKVImaging,
      ebsd: zmccTransactionDetails.ebsd,
      materialContrastImaging: zmccTransactionDetails.materialContrastImaging,
      correlativeMicroscopy: zmccTransactionDetails.correlativeMicroscopy,
      vpMode: zmccTransactionDetails.vpMode,
      smartPi: zmccTransactionDetails.smartPi,
      wetImagingEPMode: zmccTransactionDetails.wetImagingEPMode,
      threeView: zmccTransactionDetails.threeView,
      largeAreaMapping: zmccTransactionDetails.largeAreaMapping,
      raman: zmccTransactionDetails.raman,
      stemImaging : zmccTransactionDetails.stemImaging,
      cryo : zmccTransactionDetails.cryo,
      stageBias : zmccTransactionDetails.stageBias,
      mineralogic : zmccTransactionDetails.mineralogic,
      others : zmccTransactionDetails.others,
      detailedCompetitionMicroscopeModels : zmccTransactionDetails.detailedCompetitionMicroscopeModels,
      dataFromCustomerId: zmccTransactionDetails.dataFromCustomer && zmccTransactionDetails.dataFromCustomer.dataFromCustomerId,
      dataFromCustomerIdFullValue: zmccTransactionDetails.dataFromCustomer && {id: zmccTransactionDetails.dataFromCustomer.dataFromCustomerId, name: zmccTransactionDetails.dataFromCustomer.dataFromCustomerTranslated[0]['name'],staticCode:zmccTransactionDetails.dataFromCustomer.staticCode },
    },
    isFormValid: false,
  }

  const sampleDeatilsFrom  = {
    formData: {
      totalNumberOfSamples: zmccTransactionDetails.totalNumberOfSamples == 0 ? '0' : zmccTransactionDetails.totalNumberOfSamples,
      detailedDescription: zmccTransactionDetails.detailedDescription,
      manipulateTheSamplesPriorToImaging: zmccTransactionDetails.manipulateTheSamplesPriorToImaging ? 'yes' : zmccTransactionDetails.manipulateTheSamplesPriorToImaging === false ? 'no' : null,
      sampleSpecificComments: zmccTransactionDetails.sampleSpecificComments,
      statusOfSampleId: zmccTransactionDetails.statusOfSample && zmccTransactionDetails.statusOfSample.statusOfSampleId,
      statusOfSampleSolved: zmccTransactionDetails.statusOfSampleSolved ? true : zmccTransactionDetails.statusOfSampleSolved === false ? false: null,
      completedByName: zmccTransactionDetails.completedByName ? zmccTransactionDetails.completedByName : null,
    },
    isFormValid: false,
  }

  let demoCenterInformationForm = {
    formData :{
      zmccHardwareSoftwareAccessoriesList: zmccTransactionDetails.zmccHardwareSoftwareAccessoriesList,
    }
  }

  const additionalInformationForm ={
    formData: {
      detailedCommentsAdditionalInformation: zmccTransactionDetails.detailedCommentsAdditionalInformation
    },
    isFormValid: false,
  }

  const logisticsAndCoordinationForm ={
    formData: {
      zmccAttendeesList: zmccTransactionDetails.zmccAttendeesList
    },
    isFormValid: false,
  }

  const todosListForm ={
    formData:{
      zmccTodosListDataList: zmccTransactionDetails.zmccTodosListDataList,
      todoText: ''
    },
    isFormValid: false,
  }

  const lmApplicationAndSamplesForm = {
    formData:{
      primaryApplicationForThisCustomer: zmccTransactionDetails.primaryApplicationForThisCustomer,
      sampleGoingToProvideForThisDemo: zmccTransactionDetails.sampleGoingToProvideForThisDemo,
      learningAboutTheseSamples: zmccTransactionDetails.learningAboutTheseSamples,
      justificationPoints: zmccTransactionDetails.justificationPoints,
      aboutCompetitionInThisSale: zmccTransactionDetails.aboutCompetitionInThisSale,
      lmDoYouHaveDataFromCustomer: zmccTransactionDetails.doYouHaveDataFromCustomer ? 'yes' : zmccTransactionDetails.doYouHaveDataFromCustomer === false ? 'no' : null,
      lmTotalNumberOfSamples: zmccTransactionDetails.totalNumberOfSamples ? zmccTransactionDetails.totalNumberOfSamples.toString() : null ,
      statusOfSamplesForLm: zmccTransactionDetails.statusOfSamplesForXRM,
      othersComments: zmccTransactionDetails.othersComments
    },
    isFormValid: false,
  }

  let xbMainApplicationChecklistForm = {
    formData: {
      crossSectioning: zmccTransactionDetails.crossSectioning,
      stemImaging: zmccTransactionDetails.stemImaging,
      temLamellaPrep: zmccTransactionDetails.temLamellaPrep,
      eds: zmccTransactionDetails.eds,
      fibTomography: zmccTransactionDetails.fibTomography,
      ebsd: zmccTransactionDetails.ebsd,
      nanoPatterning: zmccTransactionDetails.nanoPatterning,
      threeDEds: zmccTransactionDetails.threeDEds,
      highResolutionImaging: zmccTransactionDetails.highResolutionImaging,
      threeDEbsd: zmccTransactionDetails.threeDEbsd,
      largeAreaMapping: zmccTransactionDetails.largeAreaMapping,
      correlativeMicroscopy: zmccTransactionDetails.correlativeMicroscopy,
      fsLaser: zmccTransactionDetails.fsLaser,
      othersCheckbox: zmccTransactionDetails.othersCheckbox,
      tofSims: zmccTransactionDetails.tofSims,
      xbDetailedCompetitionMicroscopeModels: zmccTransactionDetails.detailedCompetitionMicroscopeModels,
      xbDoYouHaveDataFromCustomer: zmccTransactionDetails.doYouHaveDataFromCustomer ? 'yes': zmccTransactionDetails.doYouHaveDataFromCustomer === false ? 'no': null,
    },
    isFormValid: false,
  }
  let xbSampleDeatilsFrom = {
    formData: {
      detailedDescription: zmccTransactionDetails.detailedDescription,
      xbStatusOfSampleId: zmccTransactionDetails.statusOfSample && zmccTransactionDetails.statusOfSample.statusOfSampleId,
      xbManipulateTheSamplesPriorToImaging: zmccTransactionDetails.manipulateTheSamplesPriorToImaging ? 'yes' : zmccTransactionDetails.manipulateTheSamplesPriorToImaging === false ? 'no' : null,
      statusOfSampleSolvedXb: zmccTransactionDetails.statusOfSampleSolved,
      sampleSpecificComments: zmccTransactionDetails.sampleSpecificComments,
      completedByNameXb: zmccTransactionDetails.completedByName ? zmccTransactionDetails.completedByName : null,
    },
    isFormValid: false,
  }
  const xrmDemoDetailsForm = {
    formData: {
      rankOne: zmccTransactionDetails.rankOne,
      rankTwo: zmccTransactionDetails.rankTwo,
      rankThree: zmccTransactionDetails.rankThree,
      rankFour: zmccTransactionDetails.rankFour,
      failureAnalysis: zmccTransactionDetails.failureAnalysis,
      researchRd: zmccTransactionDetails.researchRd,
      processDevelopment: zmccTransactionDetails.processDevelopment,
      production: zmccTransactionDetails.production,
      qaQc: zmccTransactionDetails.qaQc,
      flatPanel: zmccTransactionDetails.flatPanel,
      fortyX: zmccTransactionDetails.fortyX,
      art: zmccTransactionDetails.art,
      primaryGoalsOfDemo: zmccTransactionDetails.primaryGoalsOfDemo,
      customerSystemCompetition: zmccTransactionDetails.customerSystemCompetition,
      xrmDoYouHaveDataFromCustomer: zmccTransactionDetails.doYouHaveDataFromCustomer ? 'yes' : zmccTransactionDetails.doYouHaveDataFromCustomer === false ? 'no' : null,
    },
    isFormValid: false,
  }
  
  const xrmApplicationInformationForm = {
    formData: {
      detailedDescriptionOfCustomerFocus: zmccTransactionDetails.detailedDescriptionOfCustomerFocus
    },
    isFormValid: false,
  }

  const xrmSamplesDetailsForm = {
    formData: {
      totalNumberOfSamplesForXrm: zmccTransactionDetails.totalNumberOfSamples ? zmccTransactionDetails.totalNumberOfSamples.toString() : null,
      statusOfSamplesForXRM: zmccTransactionDetails.statusOfSamplesForXRM,
      xrmManipulateTheSamplesPriorToImaging: zmccTransactionDetails.manipulateTheSamplesPriorToImaging ? 'yes' : zmccTransactionDetails.manipulateTheSamplesPriorToImaging === false ? 'no' : null,

    },
    isFormValid: false,
  }
  
  const xrmSampleDescriptionForm = {
    formData: {
      describeOverallSamples: zmccTransactionDetails.describeOverallSamples
    },
    isFormValid: false,
  }

  let xrmSampleSpecsForm = {
    formData: {
      totalSampleSize: zmccTransactionDetails.totalSampleSize,
      featureSizes: zmccTransactionDetails.featureSizes,
      featureRegionOfInterest: zmccTransactionDetails.featureRegionOfInterest,
      chemicalCompositions: zmccTransactionDetails.chemicalCompositions,
      substrateOrSorroundingMaterial: zmccTransactionDetails.substrateOrSorroundingMaterial,
      contrastAgentBeenApplied: zmccTransactionDetails.contrastAgentBeenApplied ? 'yes' : zmccTransactionDetails.contrastAgentBeenApplied === false ? 'no' : null,
    },
    isFormValid: false,
  }

  let kitAttachments = zmccTransactionDetails.zmccTransactionAttachmentsList;
  kitAttachments = kitAttachments.map(file => ({ ...file, name: file.file, kitAttachmentsId: file.zmccAttachmentsId }));

  const transactionsId = zmccTransactionDetails.transactions.transactionsId;

  const zmccFullInformationId = zmccTransactionDetails.zmccFullInformationId;

  let formValues = {
    generalInformationForm: generalInformationForm,
    schedulingInformationForm: schedulingInformationForm,
    projectDetailsForm: projectDetailsForm,
    customerDetilsForm: customerDetilsForm,
    primaryPointOfContactForm: primaryPointOfContactForm,
    customerBackgroundForm: customerBackgroundForm,
    mainApplicationChecklistForm: mainApplicationChecklistForm,
    sampleDeatilsFrom: sampleDeatilsFrom,
    demoCenterInformationForm: demoCenterInformationForm,
    additionalInformationForm: additionalInformationForm,
    logisticsAndCoordinationForm: logisticsAndCoordinationForm,
    todosListForm: todosListForm,
    transactionsId : transactionsId,
    xbMainApplicationChecklistForm: xbMainApplicationChecklistForm,
    xbSampleDeatilsFrom: xbSampleDeatilsFrom,
    xrmDemoDetailsForm: xrmDemoDetailsForm,
    xrmApplicationInformationForm: xrmApplicationInformationForm,
    xrmSamplesDetailsForm: xrmSamplesDetailsForm,
    xrmSampleDescriptionForm: xrmSampleDescriptionForm,
    xrmSampleSpecsForm: xrmSampleSpecsForm,
    lmApplicationAndSamplesForm: lmApplicationAndSamplesForm,
  }

  const intendedIdsForOpportunityMandatory =[1,4,5,6,14];
  const getProjectDetailsModel =() =>{
    let projModel =[...projectDetailsModel];
    if (
      !intendedIdsForOpportunityMandatory.includes(generalInformationForm.formData.intendedForId)
    ) { 
      projModel[0].required= false;
      projModel[0].validators = []

    } else{
      projModel[0].required= true;
      projModel[0].validators = [{ check: Validators.required, message: 'requiredErrorMessage' }]
    }
    return projModel;
  }


  const validateArray = [
    { formName: 'generalInformationForm', form: generalInformationForm, model: generalInformationModel },
    { formName: 'schedulingInformationForm', form: schedulingInformationForm, model: schedulingInformationModel },
    { formName: 'projectDetailsForm', form: projectDetailsForm, model: getProjectDetailsModel() },

    { formName: 'customerDetilsForm', form: customerDetilsForm, model: customerDetilsModel },
    { formName: 'primaryPointOfContactForm', form: primaryPointOfContactForm, model: primaryPointOfContactModel },
    { formName: 'customerBackgroundForm', form: customerBackgroundForm, model: customerBackgroundModel },
  ];

  if (zmccTransactionDetails.transactions.zmccXbBooking) {
    validateArray.push({
      formName: 'xbMainApplicationChecklistForm',
      form: formValues.xbMainApplicationChecklistForm,
      model: xbMainApplicationChecklist,
    });
  }

  if (zmccTransactionDetails.transactions.zmccXrmBooking) {
    validateArray.push({
      formName: 'xrmSampleSpecsForm',
      form: formValues.xrmSampleSpecsForm,
      model: xrmSamplesSpecModel,
    });
  }


  if (zmccTransactionDetails.transactions.zmccSemBooking) {
    validateArray.push({ formName: 'mainApplicationChecklistForm', form: mainApplicationChecklistForm, model: mainApplicationChecklist})
  }

  validateArray.forEach(({ formName, form, model }) => {
    const newForm = validateForm({ form, model });
    formValues[formName] = newForm;
  });


  if (!formValues.generalInformationForm.isFormValid) {
    generalInformationForm = formValues.generalInformationForm;
  }

  if (!formValues.schedulingInformationForm.isFormValid) {
    schedulingInformationForm = formValues.schedulingInformationForm;
  }

  if (!formValues.projectDetailsForm.isFormValid) {
    projectDetailsForm = formValues.projectDetailsForm;
  }

  if (!formValues.customerDetilsForm.isFormValid) {
    customerDetilsForm = formValues.customerDetilsForm;
  }

  if (!formValues.primaryPointOfContactForm.isFormValid || !zmccTransactionDetails.customerPointOfContact) {
    let dummyFormData = {...primaryPointOfContactForm}
    if (zmccTransactionDetails.pocName == "" || zmccTransactionDetails.pocName ==null){
      dummyFormData = {formData: {...dummyFormData['formData'],pocNameError: 'requiredErrorMessage'}, isFormValid: false}
    }
    if (zmccTransactionDetails.pocEmail == "" || zmccTransactionDetails.pocEmail == null){ 
      dummyFormData = {formData: {...dummyFormData['formData'],pocEmailError: 'requiredErrorMessage'}, isFormValid: false}
    } 
    primaryPointOfContactForm = dummyFormData;
  }

  if (!formValues.customerBackgroundForm.isFormValid) {
    customerBackgroundForm = formValues.customerBackgroundForm;
  }

  if (zmccTransactionDetails.transactions.zmccSemBooking && !formValues.mainApplicationChecklistForm.isFormValid) {
    mainApplicationChecklistForm= formValues.mainApplicationChecklistForm;
  }

  if (zmccTransactionDetails.transactions.zmccXbBooking && !formValues.mainApplicationChecklistForm.isFormValid) {
    xbMainApplicationChecklistForm = formValues.xbMainApplicationChecklistForm;
  }

  if (zmccTransactionDetails.transactions.zmccXrmBooking && !formValues.xrmSampleSpecsForm.isFormValid) {
    xrmSampleSpecsForm = formValues.xrmSampleSpecsForm;
  }

  if (zmccTransactionDetails.transactions.zmccLmBooking && !formValues.lmApplicationAndSamplesForm.isFormValid) {
    if (!formValues.lmApplicationAndSamplesForm.formData.primaryApplicationForThisCustomer) {
      formValues.lmApplicationAndSamplesForm.formData.primaryApplicationForThisCustomerError = 'requiredErrorMessage';
    }
    if (!formValues.lmApplicationAndSamplesForm.formData.sampleGoingToProvideForThisDemo) {
      formValues.lmApplicationAndSamplesForm.formData.sampleGoingToProvideForThisDemoError = 'requiredErrorMessage';
    }

    if (!formValues.lmApplicationAndSamplesForm.formData.learningAboutTheseSamples) {
      formValues.lmApplicationAndSamplesForm.formData.learningAboutTheseSamplesError = 'requiredErrorMessage';
    }

    if (!formValues.lmApplicationAndSamplesForm.formData.justificationPoints) {
      formValues.lmApplicationAndSamplesForm.formData.justificationPointsError = 'requiredErrorMessage';
    }

    if (!formValues.lmApplicationAndSamplesForm.formData.aboutCompetitionInThisSale) {
      formValues.lmApplicationAndSamplesForm.formData.aboutCompetitionInThisSaleError = 'requiredErrorMessage';
    }

    if (!formValues.lmApplicationAndSamplesForm.formData.lmDoYouHaveDataFromCustomer) {
      formValues.lmApplicationAndSamplesForm.formData.lmDoYouHaveDataFromCustomerError = 'requiredErrorMessage';
    }

    if (!formValues.lmApplicationAndSamplesForm.formData.statusOfSamplesForLm) {
      formValues.lmApplicationAndSamplesForm.formData.statusOfSamplesForLmError = 'requiredErrorMessage';
    }

    if (!formValues.lmApplicationAndSamplesForm.formData.lmTotalNumberOfSamples) {
      formValues.lmApplicationAndSamplesForm.formData.lmTotalNumberOfSamplesError = 'requiredErrorMessage';
    }
  }

  if (zmccTransactionDetails.transactions.zmccXbBooking && !formValues.xbSampleDeatilsFrom.isFormValid) {
    if (!formValues.xbSampleDeatilsFrom.formData.xbStatusOfSampleId) {
      formValues.xbSampleDeatilsFrom.formData.xbStatusOfSampleIdError = 'requiredErrorMessage';
      formValues.xbSampleDeatilsFrom.formData.xbStatusOfSampleIdFullValue = 'null';
    }

    if (!formValues.xbSampleDeatilsFrom.formData.xbManipulateTheSamplesPriorToImaging) {
      formValues.xbSampleDeatilsFrom.formData.xbManipulateTheSamplesPriorToImagingError = 'requiredErrorMessage';
      formValues.xbSampleDeatilsFrom.formData.xbManipulateTheSamplesPriorToImagingFullValue = 'null';
    }

    if (!formValues.xbSampleDeatilsFrom.formData.detailedDescription) {
      formValues.xbSampleDeatilsFrom.formData.detailedDescriptionError = 'requiredErrorMessage';
    }
    if (!formValues.xbSampleDeatilsFrom.formData.sampleSpecificComments) {
      formValues.xbSampleDeatilsFrom.formData.sampleSpecificCommentsError = 'requiredErrorMessage';
    }
  }

  if (zmccTransactionDetails.transactions.zmccSemBooking && !formValues.sampleDeatilsFrom.isFormValid) {
    if (!formValues.sampleDeatilsFrom.formData.totalNumberOfSamples) {
      formValues.sampleDeatilsFrom.formData.totalNumberOfSamplesError = 'requiredErrorMessage';
    } else if (formValues.sampleDeatilsFrom.formData.totalNumberOfSamples) {
      let result = regex.number.test(formValues.sampleDeatilsFrom.formData.totalNumberOfSamples);
      if (!result) {
        formValues.sampleDeatilsFrom.formData.totalNumberOfSamplesError = 'invalidNumberError';
      }
    }

    if (!formValues.sampleDeatilsFrom.formData.manipulateTheSamplesPriorToImaging) {
      formValues.sampleDeatilsFrom.formData.manipulateTheSamplesPriorToImagingError = 'requiredErrorMessage';
      formValues.sampleDeatilsFrom.formData.manipulateTheSamplesPriorToImagingFullValue = 'null';
    }
  }

  if(!zmccTransactionDetails.transactions.zmccXbBooking && !formValues.sampleDeatilsFrom.isFormValid){
    if (!formValues.sampleDeatilsFrom.formData.statusOfSampleId) {
      formValues.sampleDeatilsFrom.formData.statusOfSampleIdError = 'requiredErrorMessage';
      formValues.sampleDeatilsFrom.formData.statusOfSampleIdFullValue = 'null';
    }
  }

  if (zmccTransactionDetails.transactions.zmccXrmBooking && !formValues.xrmDemoDetailsForm.isFormValid) {
    if (!formValues.xrmDemoDetailsForm.formData.primaryGoalsOfDemo) {
      formValues.xrmDemoDetailsForm.formData.primaryGoalsOfDemoError = 'requiredErrorMessage';
    }
    if (!formValues.xrmDemoDetailsForm.formData.customerSystemCompetition) {
      formValues.xrmDemoDetailsForm.formData.customerSystemCompetitionError = 'requiredErrorMessage';
    }
    if (!formValues.xrmDemoDetailsForm.formData.xrmDoYouHaveDataFromCustomer) {
      formValues.xrmDemoDetailsForm.formData.xrmDoYouHaveDataFromCustomerError = 'requiredErrorMessage';
    }
  }

  if (zmccTransactionDetails.transactions.zmccXrmBooking && !formValues.xrmApplicationInformationForm.isFormValid) {
    if (!formValues.xrmApplicationInformationForm.formData.detailedDescriptionOfCustomerFocus) {
      formValues.xrmApplicationInformationForm.formData.detailedDescriptionOfCustomerFocusError =
        'requiredErrorMessage';
    }
  }

  if (zmccTransactionDetails.transactions.zmccXrmBooking && !formValues.xrmSamplesDetailsForm.isFormValid) {
    if (!formValues.xrmSamplesDetailsForm.formData.statusOfSamplesForXRM) {
      formValues.xrmSamplesDetailsForm.formData.statusOfSamplesForXRMError = 'requiredErrorMessage';
    }
    if (!formValues.xrmSamplesDetailsForm.formData.totalNumberOfSamplesForXrm) {
      formValues.xrmSamplesDetailsForm.formData.totalNumberOfSamplesForXrmError = 'requiredErrorMessage';
    }

    if (!formValues.xrmSamplesDetailsForm.formData.xrmManipulateTheSamplesPriorToImaging) {
      formValues.xrmSamplesDetailsForm.formData.xrmManipulateTheSamplesPriorToImagingError = 'requiredErrorMessage';
    }
  }

  if (zmccTransactionDetails.transactions.zmccXrmBooking && !formValues.xrmSampleDescriptionForm.isFormValid) {
    if (!formValues.xrmSampleDescriptionForm.formData.describeOverallSamples) {
      formValues.xrmSampleDescriptionForm.formData.describeOverallSamplesError = 'requiredErrorMessage';
    }
  }

  if (!formValues.demoCenterInformationForm.isFormValid) {
    if (formValues.demoCenterInformationForm.formData.zmccHardwareSoftwareAccessoriesList?.length <= 0)
     {
      if (!formValues.demoCenterInformationForm.formData.hasOwnProperty('hardOrSoftwareAccessories') || (formValues.demoCenterInformationForm.formData.hasOwnProperty('hardOrSoftwareAccessories') && (formValues.demoCenterInformationForm.formData.hardOrSoftwareAccessories === "" || formValues.demoCenterInformationForm.formData.hardOrSoftwareAccessories === null))) {
        formValues.demoCenterInformationForm.formData.hardOrSoftwareAccessoriesError = 'requiredErrorMessage';
      }
    }
  }

  if(formValues.logisticsAndCoordinationForm.formData?.zmccAttendeesList?.length === 0){
    formValues.logisticsAndCoordinationForm.formData.attendeeNameError = 'requiredErrorMessage'
    formValues.logisticsAndCoordinationForm.formData.attendeeEmailError = 'requiredErrorMessage'
    formValues.logisticsAndCoordinationForm.formData.attendeeSkillError = 'requiredErrorMessage'
  }
  
  return {
    generalInformationForm,
    schedulingInformationForm,
    projectDetailsForm,
    customerDetilsForm,
    primaryPointOfContactForm,
    customerBackgroundForm,
    mainApplicationChecklistForm,
    sampleDeatilsFrom,
    demoCenterInformationForm,
    additionalInformationForm,
    logisticsAndCoordinationForm,
    todosListForm,
    kitAttachments,
    kitAttachmentsToDelete: [],
    transactionsId,
    zmccFullInformationId,
    lmApplicationAndSamplesForm,
    xbMainApplicationChecklistForm,
    xbSampleDeatilsFrom,
    xrmDemoDetailsForm,
    xrmApplicationInformationForm,
    xrmSamplesDetailsForm,
    xrmSampleDescriptionForm,
    xrmSampleSpecsForm
  }
}

export const uploadKitAttachment =
  (files, transactionsId, updateOnEnd = false) =>
  dispatch => {
    if (updateOnEnd) {
      dispatch(changeLoader(true));
    }
    if (files.length) {
      const formData = new FormData();
      let count = 0;
      files.forEach(file => {
        if (!file.kitAttachmentsId) {
          formData.append('attachmentFile', file);
          count++;
        }
      });
      if (!count) {
        if (updateOnEnd) {
          scrollToTop(500);
          dispatch(
            batchActions([
              dispatch(toggleActionMessage(true, 'error', 'noFilesUploadedErrorMessage')),
              dispatch(changeLoader(false)),
            ]),
          );
        }
        return { success: false };
      }
      return services
        .uploadZmccAttachmentsService(transactionsId, formData)
        .then(({ status }) => {
          if (status === 200 && updateOnEnd) {
            dispatch(toggleActionMessage(true, 'success', 'kitAttachmentUploadedSuccessfully'));
            dispatch(changeLoader(false));
            scrollToTop(500);
          }
          return {success: true };
        })
        .catch(err => {
          logNetworkError(err);
          if (updateOnEnd) {
            scrollToTop(500);
            let errorMsg = err.response.data.message;
            dispatch(
              batchActions([
                dispatch(changeLoader(false)),
                dispatch(toggleActionMessage(true, 'error', `${errorMsg}`, true)),
              ]),
            );
            return {success: false };
          }
        });
    } else {
      if (updateOnEnd) {
        scrollToTop(500);
        dispatch(toggleActionMessage(true, 'error', 'noFilesUploadedErrorMessage'));
        return { success: false };
      }
    }
  };


export const handleValuesToSend = (formValues, reducer,formType, authReducer) => {
  
  let valuesToSend = {
    ...formValues.generalInformationForm.formData,
    ...formValues.schedulingInformationForm.formData,
    ...formValues.projectDetailsForm.formData,
    ...formValues.customerDetilsForm.formData,
    ...formValues.primaryPointOfContactForm.formData,
    ...formValues.customerBackgroundForm.formData,
    ...formValues.mainApplicationChecklistForm.formData,
    ...formValues.sampleDeatilsFrom.formData,
    zmccHardwareSoftwareAccessoriesList : formValues.demoCenterInformationForm.formData.zmccHardwareSoftwareAccessoriesList?.length > 0 ?
     [...formValues.demoCenterInformationForm.formData.zmccHardwareSoftwareAccessoriesList,(formValues.demoCenterInformationForm.formData.hardOrSoftwareAccessories && !reducer.onAddMoreClickedINDemoCentreTab) ?
       {zmccHardwareSoftwareAccessoriesId: null, hardwareAccessoriesText:formValues.demoCenterInformationForm.formData.hardOrSoftwareAccessories} : null].filter(item => item !== null) :
        formValues.demoCenterInformationForm.formData.hardOrSoftwareAccessories ? [{zmccHardwareSoftwareAccessoriesId: null, hardwareAccessoriesText:formValues.demoCenterInformationForm.formData.hardOrSoftwareAccessories}] : [],
    ...formValues.additionalInformationForm.formData,
    zmccAttendeesList : formValues.logisticsAndCoordinationForm.formData.zmccAttendeesList?.length >0 ? [...formValues.logisticsAndCoordinationForm.formData.zmccAttendeesList, createAttendeData(formValues.logisticsAndCoordinationForm.formData, authReducer)?.length > 0 ? createAttendeData(formValues.logisticsAndCoordinationForm.formData, authReducer)[0] : null].filter(item => item !== null) : createAttendeData(formValues.logisticsAndCoordinationForm.formData, authReducer),
    zmccTodosListDataList : formValues.todosListForm.formData.zmccTodosListDataList?.length > 0 ? [...formValues.todosListForm.formData.zmccTodosListDataList, (formValues.todosListForm.formData.todoText && !reducer.onAddMorreClickedInTodoTab) ? {zmccTodosId: null,
      zmccTodosText: formValues.todosListForm.formData.todoText,
      createdBy: authReducer?.user?.accountId,
      checkedBy: null,
      isChecked: false,
      title: null} : null].filter(item => item !== null) : formValues.todosListForm.formData.todoText ? [{
      zmccTodosId: null,
      zmccTodosText: formValues.todosListForm.formData.todoText,
      createdBy: authReducer?.user?.accountId,
      checkedBy: null,
      isChecked: false,
      title: null
    }]: [],
    transactionsId : formValues.transactionsId ? formValues.transactionsId : null,
    deleteAttachmentIds: formValues.kitAttachmentsToDelete,
    ...formValues.xbMainApplicationChecklistForm.formData,
    ...formValues.xbSampleDeatilsFrom.formData,
    ...formValues.xrmDemoDetailsForm.formData,
    ...formValues.xrmApplicationInformationForm.formData,
    ...formValues.xrmSamplesDetailsForm.formData,
    ...formValues.xrmSampleDescriptionForm.formData,
    ...formValues.xrmSampleSpecsForm.formData,
    ...formValues.lmApplicationAndSamplesForm.formData,
    deleteTodoIds : formValues.deleteTodoIds.formData.deleteTodoIds ? formValues.deleteTodoIds.formData.deleteTodoIds : []
  };
  
  const customerAttendeeAvailable = valuesToSend.zmccAttendeesList && valuesToSend.zmccAttendeesList.find(item => item.isCustomerAttendee);
  const pocAvailable = valuesToSend.zmccAttendeesList && valuesToSend.zmccAttendeesList.find(item => item.isPOCtAttendee);
  if (customerAttendeeAvailable === undefined && 
      valuesToSend.customerName !== '' && 
      valuesToSend.customerName !== null && 
      valuesToSend.customerEmail !== '' && 
      valuesToSend.customerEmail !== null &&
      formValues.isCustomerAttendeeAdded !== true
    ) {
    let dummyAttende = {
      zmccAttendeesId: null,
      attendeeName: valuesToSend.customerName,
      attendeeEmail: valuesToSend.customerEmail,
      functionData: valuesToSend?.customerFunction || null,
      decisionMaker: typeof valuesToSend.customerDescisionMaker === "boolean" ? valuesToSend.customerDescisionMaker: null,
      skillLevelId: valuesToSend.customerSkillLevelId || null,
      accomodationNeededData: null,
      flightNeededData: null,
      visitMuseumOfOpticsData: null,
      accomodationNeeded: null,
      flightNeeded: null,
      visitMuseumOfOptics: null,
      todosByAttendees: null,
      isCustomerAttendee: true,
      isPOCtAttendee: false,
    }
    valuesToSend.zmccAttendeesList.push(dummyAttende);
    valuesToSend.customerAttendeeAdded = true;
  }
  if (pocAvailable === undefined && formValues.isPOCtAttendeeAdded !== true && valuesToSend.customerPointOfContact === 'no' && valuesToSend.pocName !== '' && valuesToSend.pocName !== null && valuesToSend.pocEmail !== '' && valuesToSend.pocEmail !== null) {
    let dummypocAttende = {
      zmccAttendeesId: null,
      attendeeName: valuesToSend.pocName,
      attendeeEmail: valuesToSend.pocEmail,
      functionData: null,
      decisionMaker: null,
      skillLevelId: null,
      accomodationNeededData: null,
      flightNeededData: null,
      visitMuseumOfOpticsData: null,
      accomodationNeeded: null,
      flightNeeded: null,
      visitMuseumOfOptics: null,
      todosByAttendees: null,
      isCustomerAttendee: false,
      isPOCtAttendee: true,
    }
    valuesToSend.zmccAttendeesList.push(dummypocAttende);
    valuesToSend.pocAttendeeAdded = true;
  }
  Object.keys(valuesToSend).forEach(key => {
    if (key.includes('Error') || key.includes('FullValue')) {
      delete valuesToSend[key];
    }
    if (key == 'customerNDA' || key == 'customerPointOfContact' || key == 'isCustomerApplyingForFunding' || key == 'isItFirstDemo' || key == 'manipulateTheSamplesPriorToImaging' || key == 'zeissCustomer' || key == 'precallWithZmcc' || key === 'doYouHaveDataFromCustomer' || key === 'contrastAgentBeenApplied'){
      valuesToSend[key] = valuesToSend[key] == 'yes' ? true : valuesToSend[key] == 'no' ? false : valuesToSend[key];
    }
    if (key == 'suggestedDate' || key == 'alternateDate' || key == 'dueDate'){
      valuesToSend[key] = valuesToSend[key] ? moment(valuesToSend[key]).format('YYYY-MM-DD') : null;
    }
    if (key == 'additionalInstrument'){
      valuesToSend[key] = valuesToSend[key] == null ? [] : valuesToSend[key].length >0 ? valuesToSend[key] : [];
    }
    if ((key === 'xbDoYouHaveDataFromCustomer' && (formType == 'XB' || formType == 'xb')) || (key === 'lmDoYouHaveDataFromCustomer' && (formType == 'LM' || formType == 'lm')) || (key === 'xrmDoYouHaveDataFromCustomer' && (formType == 'XRM' || formType == 'xrm'))) {
      valuesToSend['doYouHaveDataFromCustomer'] = valuesToSend[key] == 'yes' ? true : valuesToSend[key] == 'no' ? false : valuesToSend[key]
      delete valuesToSend['xbDoYouHaveDataFromCustomer','lmDoYouHaveDataFromCustomer', 'xrmDoYouHaveDataFromCustomer' ]
    }
    if ((key === 'xbManipulateTheSamplesPriorToImaging' && formType == 'XB') || (key === 'xrmManipulateTheSamplesPriorToImaging' && formType == 'XRM') ) {
      valuesToSend['manipulateTheSamplesPriorToImaging'] = valuesToSend[key] == 'yes' ? true : valuesToSend[key] == 'no' ? false : valuesToSend[key]
      delete valuesToSend['xbManipulateTheSamplesPriorToImaging', 'xrmManipulateTheSamplesPriorToImaging']
    }
    if ((key === 'totalNumberOfSamplesForXrm' && formType == 'XRM')) {
      valuesToSend['totalNumberOfSamples'] = valuesToSend[key]
      delete valuesToSend['totalNumberOfSamplesForXrm']
    }
    if ((key === 'statusOfSamplesForLm' && formType == 'LM')) {
      valuesToSend['statusOfSamplesForXRM'] = valuesToSend[key]
      delete valuesToSend['statusOfSamplesForLm']
    }
    if (key === 'xbDetailedCompetitionMicroscopeModels' && formType == 'XB') {
      valuesToSend['detailedCompetitionMicroscopeModels'] = valuesToSend[key]
      delete valuesToSend['xbDetailedCompetitionMicroscopeModels']
    }
    if (key === 'lmTotalNumberOfSamples' && formType === 'LM') {
      valuesToSend['totalNumberOfSamples'] = valuesToSend[key]
      delete valuesToSend['lmTotalNumberOfSamples']
    }
    if (key === 'xbStatusOfSampleId' && formType == 'XB') {
      valuesToSend['statusOfSampleId'] = valuesToSend[key]
      delete valuesToSend['xbStatusOfSampleId']
    }
    if (key === 'zmccSystemCategoriesId') {
      valuesToSend['zmccSystemCategoriesId'] = valuesToSend[key] !== '' ? valuesToSend[key] : null
    }
    if (key === 'crmTaskId') {
      valuesToSend['crmTaskId'] = valuesToSend[key] !== '' ? valuesToSend[key] : null
    }
    if (key === 'sbuType') {
      valuesToSend['sbuType'] = valuesToSend[key] ? valuesToSend[key] : null
    }
    
    if(formType === 'SEM' && (valuesToSend.statusOfSampleSolved && !valuesToSend.completedByName)){
      valuesToSend['completedByName'] = `${authReducer?.user?.firstName} ${authReducer?.user?.lastName}`
    } 
    if (!valuesToSend.statusOfSampleSolved && formType === 'SEM') {
      valuesToSend['completedByName'] = ''
    }

    if (key === 'statusOfSampleSolvedXb' && formType === 'XB') {
      valuesToSend['statusOfSampleSolved'] = valuesToSend[key] ? valuesToSend[key] : null
    }
    if(formType === 'XB' && valuesToSend.statusOfSampleSolvedXb && !valuesToSend.completedByNameXb){
      valuesToSend['completedByName'] = `${authReducer?.user?.firstName} ${authReducer?.user?.lastName}`
    } 
    if (formType === 'XB' && !valuesToSend.statusOfSampleSolvedXb) {
      valuesToSend['completedByName'] = ''
    }
    if (formType === 'XB' && key === 'completedByNameXb') {
      valuesToSend['completedByName'] = valuesToSend[key]
    }

  });

  const attachmentsToUpload = reducer.kitAttachments.filter(file => !file.kitAttachmentsId);
  if (formValues.schedulingInformationForm.formData){
    if (formValues.schedulingInformationForm.formData.kitInformationId){
      valuesToSend = {...valuesToSend, kitInformationId:formValues.schedulingInformationForm.formData.kitInformationId}
    }
    if (formValues.schedulingInformationForm.formData.suggestedDate){
      valuesToSend = {...valuesToSend,  suggestedDate : moment(formValues.schedulingInformationForm.formData.suggestedDate).format('YYYY-MM-DD') }
    }
    if (formValues.schedulingInformationForm.formData.alternateDate){
      valuesToSend = {...valuesToSend, alternateDate: moment(formValues.schedulingInformationForm.formData.alternateDate).format("YYYY-MM-DD")}
    }
    if (formValues.schedulingInformationForm.formData.dueDate){
      valuesToSend = {...valuesToSend, dueDate: moment(formValues.schedulingInformationForm.formData.dueDate).format("YYYY-MM-DD")}
    }
    if (formValues.schedulingInformationForm.formData.additionalInstrument){
      valuesToSend = {...valuesToSend, additionalInstrument: formValues.schedulingInformationForm.formData.additionalInstrument}
    }
    if (formValues.schedulingInformationForm.formData.zmccSystemCategoriesId){
      valuesToSend = {...valuesToSend, zmccSystemCategoriesId:formValues.schedulingInformationForm.formData.zmccSystemCategoriesId}
    }

  }
  return{valuesToSend, attachmentsToUpload}
}

export const saveSemTransaction = (actionValue = 'save', history, formType, tabId) => async (dispatch,getState) => {

  let { createSEMFormReducer, authReducer } = getState();
  let formValues = {
    generalInformationForm: createSEMFormReducer.generalInformationForm,
    schedulingInformationForm: createSEMFormReducer.schedulingInformationForm,
    projectDetailsForm: createSEMFormReducer.projectDetailsForm,
    customerDetilsForm: createSEMFormReducer.customerDetilsForm,
    primaryPointOfContactForm: createSEMFormReducer.primaryPointOfContactForm,
    customerBackgroundForm: createSEMFormReducer.customerBackgroundForm,
    mainApplicationChecklistForm: createSEMFormReducer.mainApplicationChecklistForm,
    sampleDeatilsFrom: createSEMFormReducer.sampleDeatilsFrom,
    demoCenterInformationForm: createSEMFormReducer.demoCenterInformationForm,
    additionalInformationForm: createSEMFormReducer.additionalInformationForm,
    logisticsAndCoordinationForm: createSEMFormReducer.logisticsAndCoordinationForm,
    todosListForm: createSEMFormReducer.todosListForm,
    transactionsId : createSEMFormReducer.transactionsId,
    kitAttachmentsToDelete: createSEMFormReducer.kitAttachmentsToDelete,
    xbMainApplicationChecklistForm: createSEMFormReducer.xbMainApplicationChecklistForm,
    xbSampleDeatilsFrom: createSEMFormReducer.xbSampleDeatilsFrom,
    xrmDemoDetailsForm: createSEMFormReducer.xrmDemoDetailsForm,
    xrmApplicationInformationForm: createSEMFormReducer.xrmApplicationInformationForm,
    xrmSamplesDetailsForm: createSEMFormReducer.xrmSamplesDetailsForm,
    xrmSampleDescriptionForm: createSEMFormReducer.xrmSampleDescriptionForm,
    xrmSampleSpecsForm: createSEMFormReducer.xrmSampleSpecsForm,
    lmApplicationAndSamplesForm: createSEMFormReducer.lmApplicationAndSamplesForm,
    deleteTodoIds: createSEMFormReducer.deleteTodoIds,
    isCustomerAttendeeAdded: createSEMFormReducer.isCustomerAttendeeAdded,
    isPOCtAttendeeAdded: createSEMFormReducer.isPOCtAttendeeAdded
  }

  const validateArray = [
    { formName: 'generalInformationForm', form: formValues.generalInformationForm, model: generalInformationModel },
    { formName: 'schedulingInformationForm', form: formValues.schedulingInformationForm, model: schedulingInformationModel },
    { formName: 'projectDetailsForm', form: formValues.projectDetailsForm, model: projectDetailsModel },

    { formName: 'customerDetilsForm', form: formValues.customerDetilsForm, model: customerDetilsModel },
    { formName: 'primaryPointOfContactForm', form: formValues.primaryPointOfContactForm, model: primaryPointOfContactModel },
    { formName: 'customerBackgroundForm', form: formValues.customerBackgroundForm, model: customerBackgroundModel },
  ];

  if (formType === 'SEM') {
    validateArray.push({ formName: 'mainApplicationChecklistForm', form: formValues.mainApplicationChecklistForm, model: mainApplicationChecklist})
  }

  validateArray.forEach(({ formName, form, model }) => {
    const newForm = validateForm({ form, model });
    formValues[formName] = newForm;
  });

  if (actionValue === 'save'){
    if(formValues.schedulingInformationForm.formData.suggestedDate  &&  formValues.schedulingInformationForm.formData.alternateDate){
      if (new Date(formValues.schedulingInformationForm.formData.suggestedDate).getDate() === new Date(formValues.schedulingInformationForm.formData.alternateDate).getDate()){
        dispatch(toggleActionMessage(true, 'error', 'suggestedAndALternateCantBeSame'));
        scrollToTop(500);
        return { success: false };
      }
    }


    if (!formValues.generalInformationForm.isFormValid) {
      if (formValues.generalInformationForm && formValues.generalInformationForm.formData){
        const needs=['emailError', 'mobileError'];
        const errorMessages =['invalidEmailError', 'alphanumericError'];
        Object.keys(formValues.generalInformationForm.formData).forEach(key => {
          if (!needs.includes(key) && key.includes('Error')) {
            delete formValues.generalInformationForm.formData[key];
          } else if (key === 'emailError' && formValues.generalInformationForm.formData.emailError === 'requiredErrorMessage'){
            delete formValues.generalInformationForm.formData[key];
          }
        });
        if (formValues.generalInformationForm.formData.emailError === 'invalidEmailError' || formValues.generalInformationForm.formData.mobileError === 'alphanumericError') {
          dispatch(updateSEMForm({ generalInformationForm: {formData: {...formValues.generalInformationForm.formData}, isFormValid: false} }));
          dispatch(toggleActionMessage(true, 'error', 'formIsInvalidWithValidations'));
          scrollToTop(500);
          return { success: false };
        }
      }
    }

    if (!formValues.projectDetailsForm.isFormValid) {
      if (formValues.projectDetailsForm && formValues.projectDetailsForm.formData){
        Object.keys(formValues.projectDetailsForm.formData).forEach(key => {
          if (key.includes('Error') && key !== 'opportunityIdError') {
            delete formValues.projectDetailsForm.formData[key];
          } else if (key === 'opportunityIdError' && formValues.projectDetailsForm.formData.opportunityIdError === 'requiredErrorMessage'){
            delete formValues.projectDetailsForm.formData[key];
          }
        });

        if (formValues.projectDetailsForm.formData.opportunityIdError === 'invalidNumberError' || formValues.projectDetailsForm.formData.opportunityIdError === strings.pleaseMindThatFieldNeedsToHave(9)) {
          dispatch(updateSEMForm({ projectDetailsForm: formValues.projectDetailsForm }));
          dispatch(toggleActionMessage(true, 'error', 'formIsInvalidWithValidations'));
          scrollToTop(500);
          return { success: false };
        }

        const result = formValues.projectDetailsForm.formData.opportunityId.length == 9 && regex.validCrmCheck.test(formValues.projectDetailsForm.formData.opportunityId)
        || regex.validCrmCheckUk.test(formValues.projectDetailsForm.formData.opportunityId);
        if (formValues.projectDetailsForm.formData.opportunityId.length == 9 && !result){
          formValues.projectDetailsForm.formData.opportunityIdError = 'correctOpportunityMessage';
          dispatch(updateSEMForm({ projectDetailsForm: formValues.projectDetailsForm }));
          dispatch(toggleActionMessage(true, 'error', 'formIsInvalidWithValidations'));
          scrollToTop(500);
          return { success: false };
        }
      }

    }


    if (!formValues.customerDetilsForm.isFormValid) {
      if (formValues.customerDetilsForm && formValues.customerDetilsForm.formData && formValues.customerDetilsForm.formData.customerEmailError){
        Object.keys(formValues.customerDetilsForm.formData).forEach(key => {
          if (key.includes('Error') && key !== 'customerEmailError') {
            delete formValues.customerDetilsForm.formData[key];
          } else if (key === 'customerEmailError' && formValues.customerDetilsForm.formData.customerEmailError === 'requiredErrorMessage'){
            delete formValues.customerDetilsForm.formData[key];
          }
        });

        if (formValues.customerDetilsForm.formData.customerEmailError === 'invalidEmailError') {
          dispatch(updateSEMForm({ customerDetilsForm: formValues.customerDetilsForm }));
          dispatch(toggleActionMessage(true, 'error', 'formIsInvalidWithValidations'));
          scrollToTop(500);
          return { success: false };
        }
      }
    }

    if (!formValues.primaryPointOfContactForm.isFormValid) {
      if (formValues.primaryPointOfContactForm && formValues.primaryPointOfContactForm.formData && formValues.primaryPointOfContactForm.formData.pocEmailError){
        Object.keys(formValues.primaryPointOfContactForm.formData).forEach(key => {
          if (key.includes('Error') && key !== 'pocEmailError') {
            delete formValues.primaryPointOfContactForm.formData[key];
          } else if (key === 'pocEmailError' && formValues.primaryPointOfContactForm.formData.pocEmailError === 'requiredErrorMessage'){
            delete formValues.primaryPointOfContactForm.formData[key];
          }
        });

        if (formValues.primaryPointOfContactForm.formData.pocEmailError === 'invalidEmailError') {
          dispatch(updateSEMForm({ primaryPointOfContactForm: formValues.primaryPointOfContactForm }));
          dispatch(toggleActionMessage(true, 'error', 'formIsInvalidWithValidations'));
          scrollToTop(500);
          return { success: false };
        }
      }
    }

    if (!formValues.sampleDeatilsFrom.isFormValid) {
      if (formValues.sampleDeatilsFrom && formValues.sampleDeatilsFrom.formData && formValues.sampleDeatilsFrom.formData.totalNumberOfSamples){
        Object.keys(formValues.sampleDeatilsFrom.formData).forEach(key => {
          if (key.includes('Error') && key !== 'totalNumberOfSamplesError') {
            delete formValues.sampleDeatilsFrom.formData[key];
          } else if (key === 'totalNumberOfSamplesError' && formValues.sampleDeatilsFrom.formData.totalNumberOfSamplesError === 'requiredErrorMessage'){
            delete formValues.sampleDeatilsFrom.formData[key];
          }
        }); 
        if (formValues.sampleDeatilsFrom.formData.totalNumberOfSamples) {
          let result = regex.number.test(formValues.sampleDeatilsFrom.formData.totalNumberOfSamples);
          if (!result){
            formValues.sampleDeatilsFrom.formData.totalNumberOfSamplesError = 'invalidNumberError';
            dispatch(updateSEMForm({ sampleDeatilsFrom: formValues.sampleDeatilsFrom }));
            dispatch(toggleActionMessage(true, 'error', `${strings.totalNumberOfSamples} ${strings.invalidNumberError}`, true));
            scrollToTop(500);
            return { success: false };
          }
        }
        
      }
      
      if (formValues.sampleDeatilsFrom.formData.statusOfSampleSolved && !formValues.sampleDeatilsFrom.formData.completedByName) {
        formValues.sampleDeatilsFrom.formData.completedByNameError = 'requiredErrorMessage';
        dispatch(updateSEMForm({ sampleDeatilsFrom: formValues.sampleDeatilsFrom }));
        dispatch(toggleActionMessage(true, 'error', 'formIsInvalidWithValidations'));
        scrollToTop(500);
        return { success: false };
      }
    }
    if (!formValues.xbSampleDeatilsFrom.isFormValid && formType === 'XB') {
      if (formValues.xbSampleDeatilsFrom.formData.statusOfSampleSolvedXb && formValues.xbSampleDeatilsFrom.formData.completedByNameXb === "") {
        formValues.xbSampleDeatilsFrom.formData.completedByNameXbError = 'requiredErrorMessage';
        dispatch(updateSEMForm({ xbSampleDeatilsFrom: formValues.xbSampleDeatilsFrom }));
        dispatch(toggleActionMessage(true, 'error', 'formIsInvalidWithValidations'));
        scrollToTop(500);
        return { success: false };
      }
    }
    if (!formValues.logisticsAndCoordinationForm.formData.isFormValid) {
      if (formValues.logisticsAndCoordinationForm.formData.attendeeEmail && formValues.logisticsAndCoordinationForm.formData.attendeeEmail !=="" && formValues.logisticsAndCoordinationForm.formData.attendeeEmail !== null) {
        let result = regex.email.test(formValues.logisticsAndCoordinationForm.formData.attendeeEmail);
        if (!result) {
          formValues.logisticsAndCoordinationForm.formData.attendeeEmailError = 'invalidEmailError';
          dispatch(updateSEMForm({ logisticsAndCoordinationForm: formValues.logisticsAndCoordinationForm }));
          dispatch(toggleActionMessage(true, 'error', 'formIsInvalidWithValidations'));
          scrollToTop(500);
          return { success: false };
        }
      }
      let  data = formValues.logisticsAndCoordinationForm.formData
      if (data.accomodationNeeded === true && (data.accomodationNeededData === '' || data.accomodationNeededData === null || data.accomodationNeededData === undefined)) {
        formValues.logisticsAndCoordinationForm.formData.accomodationNeededDataError = 'requiredErrorMessage';
        dispatch(updateSEMForm({ logisticsAndCoordinationForm: formValues.logisticsAndCoordinationForm }));
        dispatch(toggleActionMessage(true, 'error', 'formIsInvalidWithValidations'));
          scrollToTop(500);
          return { success: false };
      }
      if(data.flightNeeded === true && (data.flightNeededData === "" || data.flightNeededData === null || data.flightNeededData === undefined)){
        formValues.logisticsAndCoordinationForm.formData.flightNeededDataError = 'requiredErrorMessage';
        dispatch(updateSEMForm({ logisticsAndCoordinationForm: formValues.logisticsAndCoordinationForm }));
        dispatch(toggleActionMessage(true, 'error', 'formIsInvalidWithValidations'));
          scrollToTop(500);
          return { success: false };
      
      }
      if (data.visitMuseumOfOptics === true &&  (data.visitMuseumOfOpticsData === "" || data.visitMuseumOfOpticsData === null || data.visitMuseumOfOpticsData === undefined)) {
        formValues.logisticsAndCoordinationForm.formData.visitMuseumOfOpticsDataError = 'requiredErrorMessage';
        dispatch(updateSEMForm({ logisticsAndCoordinationForm: formValues.logisticsAndCoordinationForm }));
        dispatch(toggleActionMessage(true, 'error', 'formIsInvalidWithValidations'));
          scrollToTop(500);
          return { success: false };
      }
    }
  }

  let validations = validateNameEmailAndFunctionInAttendees(formValues.logisticsAndCoordinationForm)
  if(!validations.allowedToSave){
    dispatch(toggleActionMessage(true, 'error', 'pleaseFillRequiredFieldsForAttendee'));
    dispatch(updateSEMForm({logisticsAndCoordinationForm: { formData: {...formValues.logisticsAndCoordinationForm.formData, ...validations.nameEmailAndFunctionErrors}}}))
    scrollToTop(500);
    return { success: false };
  }

  if(!validations.allEmpty && !validations.isValid){
    dispatch(toggleActionMessage(true, 'error', 'pleaseFillRequiredFieldsForAttendee'));
    dispatch(updateSEMForm({logisticsAndCoordinationForm: { formData: {...formValues.logisticsAndCoordinationForm.formData, ...validations.nameEmailAndFunctionErrors}}}))
    scrollToTop(500);
    return { success: false };
  }

  if (actionValue !== 'save') {
    if (!formValues.generalInformationForm.isFormValid) {
      dispatch(updateSEMForm({ generalInformationForm: formValues.generalInformationForm }));
    }

    if (!formValues.schedulingInformationForm.isFormValid) {
      dispatch(updateSEMForm({ schedulingInformationForm: formValues.schedulingInformationForm }));
    }

    if (!formValues.projectDetailsForm.isFormValid) {
      dispatch(updateSEMForm({ projectDetailsForm: formValues.projectDetailsForm }));
    }

    if (!formValues.customerDetilsForm.isFormValid) {
      dispatch(updateSEMForm({ customerDetilsForm: formValues.customerDetilsForm }));
    }

    if (!formValues.primaryPointOfContactForm.isFormValid) {
      dispatch(updateSEMForm({ primaryPointOfContactForm: formValues.primaryPointOfContactForm }));
    }

    if (!formValues.customerBackgroundForm.isFormValid) {
      dispatch(updateSEMForm({ customerBackgroundForm: formValues.customerBackgroundForm }));
    }

    if (formType === 'SEM' && !formValues.mainApplicationChecklistForm.isFormValid) {
      dispatch(updateSEMForm({ mainApplicationChecklistForm: formValues.mainApplicationChecklistForm }));
    }


    if (formType === 'SEM' &&!formValues.sampleDeatilsFrom.isFormValid) {
        if(!formValues.sampleDeatilsFrom.formData.totalNumberOfSamples){
          formValues.sampleDeatilsFrom.formData.totalNumberOfSamplesError = 'requiredErrorMessage';
          dispatch(updateSEMForm({ sampleDeatilsFrom: formValues.sampleDeatilsFrom }));
        }
        else if (formValues.sampleDeatilsFrom.formData.totalNumberOfSamples) {
          let result = regex.number.test(formValues.sampleDeatilsFrom.formData.totalNumberOfSamples);
          if (!result){
            formValues.sampleDeatilsFrom.formData.totalNumberOfSamplesError = 'invalidNumberError';
            dispatch(updateSEMForm({ sampleDeatilsFrom: formValues.sampleDeatilsFrom }));
          }
        }

        if(!formValues.sampleDeatilsFrom.formData.statusOfSampleId){
          formValues.sampleDeatilsFrom.formData.statusOfSampleIdError = 'requiredErrorMessage';
          formValues.sampleDeatilsFrom.formData.statusOfSampleIdFullValue = 'null';
          dispatch(updateSEMForm({ sampleDeatilsFrom: formValues.sampleDeatilsFrom }));
        }

        if(!formValues.sampleDeatilsFrom.formData.manipulateTheSamplesPriorToImaging){
          formValues.sampleDeatilsFrom.formData.manipulateTheSamplesPriorToImagingError = 'requiredErrorMessage';
          formValues.sampleDeatilsFrom.formData.manipulateTheSamplesPriorToImagingFullValue = 'null';
          dispatch(updateSEMForm({ sampleDeatilsFrom: formValues.sampleDeatilsFrom }));
        }
      }

      if (!formValues.demoCenterInformationForm.isFormValid) {
        if(!formValues.demoCenterInformationForm.formData.hasOwnProperty('zmccHardwareSoftwareAccessoriesList') || formValues.demoCenterInformationForm.formData.zmccHardwareSoftwareAccessoriesList.length <= 0){
          formValues.demoCenterInformationForm.formData.hardOrSoftwareAccessoriesError = 'requiredErrorMessage';
          dispatch(updateSEMForm({ demoCenterInformationForm: formValues.demoCenterInformationForm }));
        }
      }

      if (formType === 'SEM' && (!formValues.generalInformationForm.isFormValid || !formValues.schedulingInformationForm.isFormValid || !formValues.projectDetailsForm.isFormValid || !formValues.customerDetilsForm.isFormValid || !formValues.primaryPointOfContactForm.isFormValid || !formValues.customerBackgroundForm.isFormValid || 
        !formValues.mainApplicationChecklistForm.isFormValid || 
        formValues.sampleDeatilsFrom.formData.totalNumberOfSamplesError == 'requiredErrorMessage' || formValues.sampleDeatilsFrom.formData.totalNumberOfSamplesError == 'invalidNumberError' || formValues.sampleDeatilsFrom.formData.statusOfSampleIdError == 'requiredErrorMessage' || formValues.sampleDeatilsFrom.formData.manipulateTheSamplesPriorToImagingError  == 'requiredErrorMessage' ||
         formValues.demoCenterInformationForm.formData.hardOrSoftwareAccessoriesError == 'requiredErrorMessage')){
        dispatch(toggleActionMessage(true, 'error', `${strings.formIsInvalid} ${strings.clickBackAndFillThem}`));
        scrollToTop(500);
        return { success: false };
      }

  }

    const {valuesToSend, attachmentsToUpload} = handleValuesToSend(formValues, createSEMFormReducer, formType, authReducer)
  

  dispatch(changeLoader(true));
  try {
    const url = URLS.saveZmccSemTransaction(actionValue, formType);
    const { data } = await fetchMasterDataServicePost(url,valuesToSend);
    const uploadAttachments = !!attachmentsToUpload.length;
    if (data) {
      saveToStorage(identifiers.zmccCrmParams, null)
    }
    dispatch(changeLoader(false));
    dispatch(isClickedOnAddDemoCenter(false));
    dispatch(isClickedOnAdInTodoTab(false));
    let successValue;
    if (uploadAttachments) {
      const {success} = await dispatch(uploadKitAttachment(attachmentsToUpload, data.transactions.transactionsId, true));
      successValue = success;
      scrollToTop(500);
    }

    if (data.zmccSavedStatus  == 'PARTIAL' && (successValue == true || !uploadAttachments)){
      dispatch(toggleActionMessage(true, 'success', 'valuesSavedSuccessMessage'));
      scrollToTop(500);
        dispatch(getUnexecutedSemTransaction(authReducer.user, createSEMFormReducer.marketSegments, data.transactions.transactionsId))
         history.push(`/${formType}/${data.transactions.transactionsId}`);
         saveToStorage(identifiers.zmccDrfTabId, {tabId: tabId, url: `/${formType}/${data.transactions.transactionsId}`});
    }

    else if (data.zmccSavedStatus  == 'CREATED'){
      dispatch(toggleActionMessage(true, 'success', 'zmccSuccessCreation'));
      dispatch({
        type: RESET_CREATE_SEM_FORM,
        payload: {},
      });
      dispatch({
        type: FETCH_TRANSACTION_ADDRESS,
        payload: {},
      })
      history.push('/my-bookings?type=zmcc');
    }
   
    return { success: true, 'data': data };
  }
  catch (error){
    dispatch(changeLoader(false));
    scrollToTop(500); 
    if (error?.response?.data?.status === 422 || error?.response?.data?.status === 403) {
      dispatch(toggleActionMessage(true, 'error', error.response.data.message, true));
    }else{
      dispatch(toggleActionMessage(true, 'error', 'somethingWentWrongMessage'));
    }
    return { success: false };
  }
}

export const fetchLoansIntended = () => async dispatch => {
  try {
    const { data } = await fetchMasterDataService(URLS.loansIntended);
    dispatch({ type: FETCH_LOANS_INTENDED, payload: data });
    return { success: true };
  } catch (error) {
    dispatch({ type: FETCH_LOANS_INTENDED, payload: [] });
    return { success: false };
  }
};

export const fetchBestWayToReach = () => async dispatch => {
  try {
    const { data } = await fetchMasterDataService(URLS.bestwayToReach);
    dispatch({ type: FETCH_BESTWAY_TO_REACH, payload: data });
    return { success: true };
  } catch (error) {
    dispatch({ type: FETCH_BESTWAY_TO_REACH, payload: [] });
    return { success: false };
  }
};

export const fetchDataWithin = () => async dispatch => {
  try {
    const { data } = await fetchMasterDataService(URLS.dataWithin);
    dispatch({ type: FETCH_DATA_WITHIN, payload: data });
    return { success: true };
  } catch (error) {
    dispatch({ type: FETCH_DATA_WITHIN, payload: [] });
    return { success: false };
  }
};

export const fetchDataWithCustomer = () => async dispatch => {
  try {
    const { data } = await fetchMasterDataService(URLS.dataFromCustomer);
    dispatch({ type: FETCH_DATA_WITH_CUSTOMER, payload: data });
    return { success: true };
  } catch (error) {
    dispatch({ type: FETCH_DATA_WITH_CUSTOMER, payload: [] });
    return { success: false };
  }
};

export const fetchRelationShipWithZeiss = () => async dispatch => {
  try {
    const { data } = await fetchMasterDataService(URLS.realtionshipWithZeiss);
    dispatch({ type: FETCH_RELATIONSHIP_WITH_ZEISS, payload: data });
    return { success: true };
  } catch (error) {
    dispatch({ type: FETCH_RELATIONSHIP_WITH_ZEISS, payload: [] });
    return { success: false };
  }
};

export const fetchMarketSegment = () => async dispatch => {
  try {
    const { data } = await fetchMasterDataService(URLS.marketSegments);
    dispatch({ type: FETCH_MARKET_SEGMENTS, payload: data });
    return { success: true };
  } catch (error) {
    dispatch({ type: FETCH_MARKET_SEGMENTS, payload: [] });
    return { success: false };
  }
};

export const fetchStatusOFSamples = () => async dispatch => {
  try {
    const { data } = await fetchMasterDataService(URLS.statusOfSamples);
    dispatch({ type: FETCH_STATUS_OF_SAMPLE, payload: data });
    return { success: true };
  } catch (error) {
    dispatch({ type: FETCH_STATUS_OF_SAMPLE, payload: [] });
    return { success: false };
  }
};

export const fetchPrimaryInstruments = (stockID, zmccSystemCategoriesId) => async (dispatch, getState) => {
  const kitStatus = ['kitStatus.labAndLocation:ne:14', 'or', 'kitStatus.labAndLocation:ne:15'] ;
  const categoryFilter =[];

  const filter = [
    [
      [
        kitStatus,
        'and',
        'deleted:eq:false',
        'and',
        'kitName:nn:',
        'and',
        [
          'availabilityTo:goe:' + moment().format('YYYY-MM-DD HH:mm:ss'),
          'or',
          ['availabilityTo:em:', 'or', 'availabilityTo:nl:'],
        ],
        'and',
        ['kitCondition.id:ne:2', 'and', 'kitCondition.id:ne:4', 'or', 'kitCondition.id:nl:0'],
      ],
    ],
  ];

  const stockFilter = [`stock.id:eq:${stockID.stockInformationId}`];
  const systemCategoryFilter = [`zmccSystemClass.zmccSystemCategoryId:eq:${zmccSystemCategoriesId}`];
  if (stockID.stockInformationId){
    filter.push('and');
    filter.push(stockFilter);
  }
  if (zmccSystemCategoriesId){
    filter.push('and');
    filter.push(systemCategoryFilter);
  }
  filter.push('or');
  filter.push(categoryFilter);

  const data1 = {
    filter,
    graphql: 'kitName,kitInformationId,zmccCalendarBlocked[id,zmccCalendarBlockStatus,from,to,zmccCalendarBlockStatus],zmccCalendarBooked[transactionsId,transactionNumber,from,to,kitInformationId,zmccCalendarBlockId,eventStartTime,eventEndTime]',
    page: 1,
    size: -1
  };


  return zeissInstance
    .post(
      `${URLS.zmccCalendarSearch}?calendarSearch=true&startRange=${moment().subtract(12, 'months').valueOf()}&endRange=${moment()
        .add(30, 'months')
        .valueOf()}`,
      data1,
    )
    .then(res => {
      if (res && res.data) {
        dispatch({ type: FETCH_PRIMARY_INSTRUMENT, payload: res.data.content });
      }
      return { success: true };
    })
    .catch(err => {
      dispatch({ type: FETCH_PRIMARY_INSTRUMENT, payload: [] });
      return { success: false };
    });
};


export const fetchAdditionalInstruments = (stockID) => async (dispatch, getState) => {
  const kitStatus = ['kitStatus.labAndLocation:ne:14', 'or', 'kitStatus.labAndLocation:ne:15'] ;
  const categoryFilter =[];

  const filter = [
    [
      [
        kitStatus,
        'and',
        'deleted:eq:false',
        'and',
        'kitName:nn:',
        'and',
        [
          'availabilityTo:goe:' + moment().format('YYYY-MM-DD HH:mm:ss'),
          'or',
          ['availabilityTo:em:', 'or', 'availabilityTo:nl:'],
        ],
        'and',
        ['kitCondition.id:ne:2', 'and', 'kitCondition.id:ne:4', 'or', 'kitCondition.id:nl:0'],
      ],
    ],
  ];

  const stockFilter = [`stock.id:eq:${stockID.stockInformationId}`];
  if (stockID.stockInformationId){
    filter.push('and');
    filter.push(stockFilter);
  }

  const data1 = {
    filter,
    graphql: 'kitName,kitInformationId,zmccCalendarBlocked[id,zmccCalendarBlockStatus,from,to,zmccCalendarBlockStatus],zmccCalendarBooked[transactionsId,transactionNumber,from,to,kitInformationId,zmccCalendarBlockId,eventStartTime,eventEndTime]',
    page: 1,
    size: -1
  };


  return zeissInstance
    .post(
      `${URLS.zmccCalendarSearch}?calendarSearch=true&startRange=${moment().subtract(12, 'months').valueOf()}&endRange=${moment()
        .add(30, 'months')
        .valueOf()}`,
      data1,
    )
    .then(res => {
      if (res && res.data) {
        dispatch({ type: FETCH_ADDITIONAL_INSTRUMENT, payload: res.data.content });
      }
      return { success: true };
    })
    .catch(err => {
      dispatch({ type: FETCH_ADDITIONAL_INSTRUMENT, payload: [] });
      return { success: false };
    });
};

export const fetchSkillLevel = () => async dispatch => {
  try {
    const { data } = await fetchMasterDataService(URLS.skillLevel);
    dispatch({ type: FETCH_SKILL_LEVEL, payload: data });
    return { success: true };
  } catch (error) {
    dispatch({ type: FETCH_SKILL_LEVEL, payload: [] });
    return { success: false };
  }
};

export const cancelSemZmccLoan = (transactionId, history) =>  async dispatch => {
  const crmParams = fetchFromStorage(identifiers.zmccCrmParams)
  if (!transactionId && crmParams === null){
    dispatch({
      type: RESET_CREATE_SEM_FORM,
      payload: {},
    });
    dispatch({
      type: FETCH_TRANSACTION_ADDRESS,
      payload: {},
    })
    history.push("/");
  }
  else {
    try {
      const { data } = await deleteMasterDataService(URLS.cancelZmccSemTransaction(transactionId));
      if (crmParams === null) {
        dispatch({
          type: RESET_CREATE_SEM_FORM,
          payload: {},
        });
        dispatch({
          type: FETCH_TRANSACTION_ADDRESS,
          payload: {},
        })
       history.push("/");
      }


      return { success: true, data };
    } catch (error) {
      dispatch(toggleActionMessage(true, 'error', 'somethingWentWrongMessage'));
      return { success: false };
    }
  }
}

export const removeLoanFromCart = (transactionId) =>  async dispatch => {
    try {
      const { data } = await deleteMasterDataService(URLS.cancelZmccSemTransaction(transactionId));
      window.location.reload();
      return { success: true, data };
    } catch (error) {
      dispatch(toggleActionMessage(true, 'error', 'somethingWentWrongMessage'));
      return { success: false };
    }
}

const setCrmData = (crmData, marketSegment, marketSubSegment, user, createSEMFormReducer)  =>{
  const generalInformationForm = {
    formData: {
      mobile: user?.phone || "",
      requesterId: user && user.accountId,
      requesterIdFullValue: user && {id : user.accountId , ...user}, 
      email: user?.email || ""
    },
    isFormValid: false,
  };
  let updatedOpportunityId 
  if (crmData.opportunityIdCRM?.length === 10 && crmData.opportunityIdCRM.startsWith("0")) {
    updatedOpportunityId = crmData.opportunityIdCRM.slice(1);
  } else{
    updatedOpportunityId = crmData.opportunityIdCRM;
  }
  const projectDetailsForm = {
    formData: {
      opportunityId : updatedOpportunityId,
      crmTaskId: crmData.taskId
    },
    isFormValid: false,
  };
  const customerDetilsForm ={
    formData: {
      customerName: `${crmData.firstname} ${crmData.lastname}`,
      customerOrganization: crmData.organization,
      customerAddress: `${crmData.address}  ${crmData.zip}  ${crmData.city}`,
      customerWebsite : crmData.website,
      customerEmail: crmData.email,
      customerMobile: crmData.phone ? `${crmData.phone && crmData.phone.replace(/\s/g, "")}`: null,
      customerDepartment: crmData.department ? crmData.department : null,
    },
    isFormValid: false,
  }

  const {customerBackgroundForm: customerBackgroundFormClone} = createSEMFormReducer;
  let selectedMarketSegmentId = customerBackgroundFormClone.formData.marketSegmentId;
  let selectedMarketSegmentFullInfo = customerBackgroundFormClone.formData.marketSegmentIdFullValue;
  let selectedMarketSubSegementId = customerBackgroundFormClone.formData.customerMarketSubsgementId;
  let selectedMarketSubsegmentFullInfo = customerBackgroundFormClone.formData.customerMarketSubsgementFullValue;
  const customerBackgroundForm = {
    formData: {
      marketSegmentId: selectedMarketSegmentId ? selectedMarketSegmentId:(marketSegment ?  marketSegment.id : null),
      marketSegmentIdFullValue: selectedMarketSegmentFullInfo ? {...selectedMarketSegmentFullInfo}:( marketSegment ? marketSegment : null),
      customerMarketSubsgementId: selectedMarketSubSegementId ? selectedMarketSubSegementId :(marketSubSegment ? marketSubSegment.id : null),
      customerMarketSubsgementFullValue: selectedMarketSubsegmentFullInfo ? {...selectedMarketSubsegmentFullInfo}: (marketSubSegment ? marketSubSegment : null),
    },
    isFormValid: false,
  }
  
  return{
    generalInformationForm,
    projectDetailsForm,
    customerDetilsForm,
    customerBackgroundForm,
  }
}

export const fetchEstimatedDays = () => async dispatch => {
  try {
    const { data } = await fetchMasterDataService(URLS.estimateDays);
    dispatch({ type: FETCH_ESTIMATE_DAYS, payload: data });
    return { success: true };
  } catch (error) {
    dispatch({ type: FETCH_ESTIMATE_DAYS, payload: [] });
    return { success: false };
  }
};

export const fetchZmccShippingSampleAddress = (warehouseId) => async dispatch => {
  try {
    const { data } = await fetchMasterDataService(URLS.getZmccShippingSampleAddress(warehouseId));
    dispatch({ type: FETCH_ZMCC_SHIPPING_ADDRESS, payload: data });
    return { success: true };
  } catch (error) {
    dispatch({ type: FETCH_ZMCC_SHIPPING_ADDRESS, payload: {} });
    return { success: false };
  }
};

export const fetchMarketSubSegment = (marketSegmentId = 0) => async dispatch => {
  try {
    const { data } = await fetchMasterDataService(`${URLS.marketSubsegment}?marketSegmentId=${marketSegmentId}`);
    dispatch({ type: FETCH_MARKET_SUB_SEGMENTS, payload: data });
    return { success: true };
  } catch (error) {
    dispatch({ type: FETCH_MARKET_SUB_SEGMENTS, payload: [] });
    return { success: false };
  }
};

export const downloadZmccDocumentFolder = () => dispatch => {
  const token = fetchFromStorage(identifiers.token);
  dispatch(changeLoader(true));
    Axios(process.env.REACT_APP_BASE_URL+URLS.zmccZipDownload, {
      method: 'GET',
      responseType: 'blob',
      headers: {
        token: `${token}`,
      },
    })
      .then(response => {
        const newFile = new Blob([response.data], { type: "application/zip" });
        saveAs(newFile, 'ZMCC Document');
        dispatch(changeLoader(false));
        return { success: true };
      })
      .catch(error => {
        dispatch(changeLoader(false));
        scrollToTop(500);
        dispatch(toggleActionMessage(true, 'error', 'fileDoesnotExist'))
        return { success: false };
      });
};

export const resetFormData = () =>({
  type: UPDATE_CREATE_SEM_FORM,
  payload: {},
});

export const deleteAttendee = ( id, user, marketSegment, transactionId,isTransactionEdit, getWholeSemTransaction ) => async dispatch => {
  try {
    dispatch(changeLoader(true));
    const { data } = await deleteMasterDataService(URLS.deleteAttendeAndTodos(id));
    dispatch(changeLoader(false));
    if (isTransactionEdit) {
      dispatch(getWholeSemTransaction(`${transactionId}?deatilPage`));
    } else {
      dispatch(getUnexecutedSemTransaction(user, marketSegment, transactionId));
    }
    return { success: true };
  } catch (error) {
    dispatch(changeLoader(false));
    return { success: false };
  }
}

export const getSystemCategoriesList = () => async dispatch => {
  try {
    const { data } = await fetchMasterDataService(URLS.systemCategories);
    dispatch({ type: FETCH_SYSTEM_CATEGORIES, payload: data });
    return { success: true };
  } catch (error) {
    dispatch({ type: FETCH_SYSTEM_CATEGORIES, payload: [] });
    return { success: false };
  }
}

const createAttendeData = (data, reducer) => {
  let tempTodosList = []
  if(((data.attendeeName && data.attendeeName !== "" && data.attendeeName !== null) || (data.attendeeEmail && data.attendeeEmail !== "" && data.attendeeEmail !== null ) || (data.function && data.function !== "" && data.function !== null) || (data.skillLevelId && data.skillLevelId !== "" && data.skillLevelId !== null) || data.accomodationNeeded === true || data.flightNeeded === true || data.visitMuseumOfOptics === true || (data.descisionMaker && data.descisionMaker !=="" && data.descisionMaker !== null))){
    if (data.accomodationNeeded === true && data.accomodationNeededData && data.accomodationNeededData !== '' && data.accomodationNeededData !== null) {
      tempTodosList.push({
        zmccTodosId: null,
        zmccTodosText: data.accomodationNeededData,
        createdBy: reducer.user.accountId,
        checkedBy: null,
        isChecked: false,
        title: 'accomodationNeeded',
        attendeAccId: '00'
      })
    }
    if (data.flightNeeded === true && data.flightNeededData && data.flightNeededData !== "" && data.flightNeededData !== null) {
      tempTodosList.push({
        zmccTodosId: null,
        zmccTodosText: data.flightNeededData,
        createdBy: reducer.user.accountId,
        checkedBy: null,
        isChecked: false,
        title: 'flightNeeded',
        attendeAccId: '01',
      })
    }
    if (data.visitMuseumOfOptics === true && data.visitMuseumOfOpticsData && data.visitMuseumOfOpticsData !== "" && data.visitMuseumOfOpticsData !== null) {
      tempTodosList.push({
        zmccTodosId: null,
        zmccTodosText: data.visitMuseumOfOpticsData,
        createdBy: reducer.user.accountId,
        checkedBy: null,
        isChecked: false,
        title: 'visitMuseumOfOptics',
        attendeAccId:'02'
      })
    }
    return[
      {
        zmccAttendeesId: null,
        attendeeName: data.attendeeName,
        attendeeEmail: data.attendeeEmail,
        functionData: data.function,
        decisionMaker: data.descisionMaker === 'yes' ? true : data.descisionMaker === 'no' ? false : null,
        skillLevelId: data.skillLevelId,
        accomodationNeededData: data.accomodationNeededData,
        flightNeededData: data.flightNeededData,
        visitMuseumOfOpticsData: data.visitMuseumOfOpticsData,
        accomodationNeeded: data.accomodationNeeded,
        flightNeeded: data.flightNeeded,
        visitMuseumOfOptics: data.visitMuseumOfOptics,
        accomodationNeeded: data.accomodationNeeded,
        flightNeeded: data.flightNeeded,
        visitMuseumOfOptics: data.visitMuseumOfOptics,
        todosByAttendees: tempTodosList,
        isCustomerAttendee: false,
        isPOCAttendee: false
      }
    ]
  }else {
    return [];
  }
  
}

export const validateNameEmailAndFunctionInAttendees = (logisticsAndCoordinationForm) => {

  let nameEmailAndFunctionErrors = {};
  let formValues = logisticsAndCoordinationForm.formData
  let name = formValues.attendeeName
  let email = formValues.attendeeEmail
  let functionData = formValues.function
  let isValid = true

  if(!name || !name.trim()){
    isValid = false;
    nameEmailAndFunctionErrors = {...nameEmailAndFunctionErrors}
  }

  if(!email || !email.trim() || Validators.email(email)?.error){
    isValid = false
    nameEmailAndFunctionErrors = {...nameEmailAndFunctionErrors,
      attendeeEmailError: !email ? 'requiredErrorMessage': Validators.email(email)?.error ? Validators.email(email, 'invalidEmailError').message: null
    }
  }

  if (formValues.descisionMaker && formValues.descisionMaker === "yes" && !formValues.skillLevelId) {
    isValid = false;
    nameEmailAndFunctionErrors = {...nameEmailAndFunctionErrors, attendeeSkillError: 'requiredErrorMessage'}
  }
  
  if ((formValues.skillLevelId || formValues.descisionMaker) && Object.keys(nameEmailAndFunctionErrors).length > 0) {
    return {allowedToSave: false, nameEmailAndFunctionErrors, isValid}
  }

  if(
    (formValues.accomodationNeeded || formValues.flightNeeded || formValues.visitMuseumOfOptics) &&
    Object.keys(nameEmailAndFunctionErrors).length > 0
  ){
    return {allowedToSave: false, nameEmailAndFunctionErrors, isValid}
  }

  if(
    (!name || (name && name.trim() === '')) &&
    (!email || (email && email.trim() === ''))  
  ){
    return {isValid, nameEmailAndFunctionErrors, allEmpty: true, allowedToSave: true}
  }else{
    return {isValid, nameEmailAndFunctionErrors, allEmpty: false, allowedToSave: true}
  }
}

export const isClickedOnAddDemoCenter = payload => (
  {

    type: CLICKED_ADD_IN_DEMO_CENTRE_TAB,
    payload,
});

export const isClickedOnAdInTodoTab = payload => (
  {

    type: CLICKED_ADD_IN_TODO_TAB,
    payload,
});


export const fetchSbuTypes = () => async dispatch => {
  try {
    const { data } = await fetchMasterDataService(URLS.sbuTypes);
    dispatch({ type: FETCH_SBU_TYPES, payload: data });
    return { success: true };
  } catch (error) {
    dispatch({ type: FETCH_SBU_TYPES, payload: [] });
    return { success: false };
  }
};



